import { type FC, useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

import { useAuth, useDebounce } from '@lib/hooks'
import { Input, SearchIcon, Spinner, Variant } from '@lib/components'
import { GivematchTestId } from '@givematch/common'

import angleRight from '~/assets/angle-right-white.svg'
import defaultCharityLogo from '~/assets/default-charity-logo.svg'
import { FundraiserLayout } from '~/components/FundraiserLayout'
import { useFundraiserPlan } from '~/hooks/useFundraiserPlan'
import { Navigation } from '~/service/navigation'
import { Tracking } from '~/service/tracking'
import { type CharityList, getCharities, getCharity } from '~/state/charity'
import { getNextStep } from '~/utils/fundraiserSteps'
import { isNonApprovedAPICharity } from '~/utils/charityAPI'
import { ModalBtn, ModalNonApprovedAPICharity } from '~/components/ModalNonApprovedAPICharity'

const CharityCard: FC<{ charityItem: CharityList }> = ({ charityItem }) => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { user } = useAuth()
  const { fundraiser, setFundraiser } = useFundraiserPlan()
  const [selectedCharityId, setSelectedCharityID] = useState('')
  const [showModal, setShowModal] = useState(false)

  const onContinue = async (selectedCharity: CharityList): Promise<void> => {
    if (isNonApprovedAPICharity(selectedCharity)) {
      console.log('[CharityCard][onContinue] non-approved charity', { selectedCharity, pathname, user })
      setShowModal(true)

      const charity = await getCharity(selectedCharity.charityID)
      Tracking.selectAPICharityNotApproved(charity, 0, pathname, user?.email)
    } else {
      if (selectedCharityId === selectedCharity.charityID) {
        setFundraiser({ ...fundraiser, charity: selectedCharity })
        Tracking.pickCharity(fundraiser, selectedCharity.name)
        navigate(getNextStep(Navigation.fundraiserSelectCharity()))
      } else {
        setSelectedCharityID(selectedCharity.charityID)
      }
    }
  }

  return (
    <>
      {/* Charity Card */}
      <div
        className={`flex justify-around mt-2  rounded-lg  border-2 ${selectedCharityId === charityItem.charityID ? 'border-gm-green' : 'border-gm-gray-300 hover:border-gm-green-200'}`}
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onClick={async () => onContinue(charityItem)}
        data-test-id={`${GivematchTestId.charityCard_}${charityItem.charityID}`}
      >
        <div className="flex px-10 py-5 w-10/12">
          <img src={`/imageuploads/charity/${charityItem.charityID}/${charityItem.logoImage}`}
               className="rounded w-1/6 object-contain" alt={`${charityItem.name} logo`}
               onError={(error) => (error.currentTarget.src = defaultCharityLogo)}/>
          <div className="ml-5 flex flex-col w-5/6">
            <span
              className="text-md font-semibold">{charityItem.name !== '' ? charityItem.name : charityItem.charityID}</span>
            <ul className="list-disc list-inside">
              {Object.values(charityItem.causes).map(cause => (
                <li className="text-sm font-light" key={cause.causeID}>{cause.name}</li>
              ))}
            </ul>
          </div>
        </div>
        <div className="w-2/12 px-1 py-2">
          <div
            className={`${selectedCharityId === charityItem.charityID ? 'block' : 'hidden'} bg-gm-green h-full flex justify-center items-center rounded-lg`}>
            <img src={angleRight} alt="right arrow" className="w-3"/>
          </div>
        </div>
      </div>

      {/* Modal */}
      {showModal && (
        <ModalNonApprovedAPICharity
          showModal={showModal}
          setShowModal={setShowModal}
          charityName={charityItem.name}
          cta1={(
            <ModalBtn primary={true} onClick={() => setShowModal(false)}>
              Select another charity
            </ModalBtn>
          )}
        />
      )}
    </>
  )
}

export const FundraiserSelectCharity: FC = () => {
  const { fundraiser } = useFundraiserPlan()
  const [searchText, setSearchText] = useState('')
  const [charities, setCharities] = useState<CharityList[]>()
  const [loading, setLoading] = useState(true)
  const [isError, setIsError] = useState(false)
  const debouncedSearchText = useDebounce(searchText)

  const fetchCharities = (searchText: string): void => {
    setLoading(true)
    setIsError(false)
    let onlyWithWeight = true
    let debouncedSearchTerm = ''
    if (searchText) {
      onlyWithWeight = false
      debouncedSearchTerm = searchText
    }
    getCharities(fundraiser.country, onlyWithWeight, debouncedSearchTerm).then((res) => {
      setCharities(res)
    }).catch(() => {
      setIsError(true)
    }).finally(() => {
      setLoading(false)
    })
  }

  useEffect(() => {
    if (debouncedSearchText.length > 2) {
      fetchCharities(debouncedSearchText)
    } else {
      fetchCharities('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchText])

  return <FundraiserLayout title="Who you're raising fund for">
    <span className="text-gm-green-500 font-semibold mt-5 text-xl">Search for a charity</span>
    <div className="relative mt-5">
      <SearchIcon className="text-gm-green-500"/>
      <Input variant={Variant.gm} value={searchText} onChange={(event) => setSearchText(event.currentTarget.value)}
             placeholder="Name, location, cause" className="w-full max-w-full pl-12 rounded" autoFocus />
    </div>
    {isError && <p className="px-4 mt-4 text-gm-red"> We were unable to fetch charity data </p>}

    {loading && <Spinner className="m-auto mt-10" />}

    {!loading && !isError && charities &&
      <>
        {!debouncedSearchText && <span className="text-gm-green-500 font-bold mt-5 text-xl mb-2">Pick from suggested charities</span>}
        <>
          {charities?.map(charity => <CharityCard charityItem={charity} />)}
        </>
      </>}
  </FundraiserLayout>
}
